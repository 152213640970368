// extracted by mini-css-extract-plugin
export var column = "Contact__column__pcXLD";
export var contactPersonContainer = "Contact__contactPersonContainer__yRkIq";
export var contactPersonDesc = "Contact__contactPersonDesc__fxV35";
export var contactPersonDescContainer = "Contact__contactPersonDescContainer__VQqqC";
export var contactPersonDescData = "Contact__contactPersonDescData__V3KqM";
export var contactPersonName = "Contact__contactPersonName__bbCB0";
export var divider = "Contact__divider__BFIxw";
export var doubleColumnContainer = "Contact__doubleColumnContainer__ZBZvq";
export var firstNameContainer = "Contact__firstNameContainer__O5f9Y";
export var flex = "Contact__flex__InW7q";
export var flexColumn = "Contact__flexColumn__qJV99";
export var formRow = "Contact__formRow__muoIu";
export var gap1 = "Contact__gap1__WowVP";
export var gap2 = "Contact__gap2__FuCNJ";
export var gap3 = "Contact__gap3__n2ce0";
export var gap4 = "Contact__gap4__d34Tt";
export var gap5 = "Contact__gap5__RjGQ9";
export var h1 = "Contact__h1__Q6yAK";
export var h2 = "Contact__h2__VFtzx";
export var iconMargin = "Contact__iconMargin__He5Qy";
export var imgPerson = "Contact__imgPerson__bs1cH";
export var lastNameContainer = "Contact__lastNameContainer__vx4UG";
export var leftContent = "Contact__leftContent__jH9eS";
export var messageField = "Contact__messageField__FpwhL";
export var pageContainer = "Contact__pageContainer__PKaMS";
export var pageContent = "Contact__pageContent__LZ2uo";
export var personContainerDesktop = "Contact__personContainerDesktop__eALM7";
export var personContainerMobile = "Contact__personContainerMobile__Ljh45";
export var phoneContainer = "Contact__phoneContainer__C4VQd";
export var phoneGhostContainer = "Contact__phoneGhostContainer__Yry9U";
export var rightContent = "Contact__rightContent__JPI2A";
export var row = "Contact__row__VxyNa";
export var submitMessage = "Contact__submitMessage__T90rz";
export var submitRow = "Contact__submitRow__ues7d";